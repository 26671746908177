import React, { useEffect, useState } from 'react'
import {render} from 'react-dom'

import {postier} from './Postier'

export default function Login() {

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')



    useEffect(()=>{
            const t = window.localStorage.getItem('token');

            console.log({t})

            if(t && t!= null && t != "undefined" && t!=""){
                localStorage.setItem("token","")
                window.location.assign(`/`)
            }
            
    },[])
    

    const login = (e)=>{
        postier.getToken(username,password)

    }

    const form = <form id="form" onSubmit={login} className="contain" action="/loginRestit" method="post">
                    <div className="LoginTitle">veuillez vous connecter  </div>
                        <input id="username" value={username} onChange={(e)=>{setUsername(e.target.value)}} name="username" className=" formulaire" placeholder="username" /><br/>
                        <input id="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} name="password" type="password" className="formulaire" placeholder="password"  /><br/>
                        <button id="submit" type="submit" className="btn-info formulaire" >Se Connecter</button>
                </form>

    return (
        <>
            
            {form}
        </>
    )
}

render(<Login/>,document.getElementById('app'))